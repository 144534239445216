@import 'components/main';
@import 'components/auth';
@import 'components/layout';
@import 'components/users';
@import 'components/subscribe';
@import 'components/subscription';
@import 'components/income';
@import 'components/expense';
@import 'components/formTools';
@import 'components/reports';
@import 'components/promo';
@import 'components/paymentData';
@import 'variables';
@import 'mixins';


.header-text {
  @include flexCenter();
}

.header-with-button {
  display: flex;
  justify-content: space-between;
}
