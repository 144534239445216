@import '../variables';
@import '../mixins';

.list-tool {
  display: flex;
  .paymentTypes {
    width: 120px;
  }
  &-input {
    min-width: 150px;
  }
  &-range {
    width: 250px;
  }
}

.search-tool {
  display: flex;
  &-input {
    width: 300px;
  }
}