@import '../variables';
@import '../mixins';

.layout {
  min-height: 100vh;
  overflow: auto;
}

.header {
  background-color: $black5;
}

.content {
  background-color: $white;
}

.footer {
  background-color: $black5;
}

.sider {
  padding-top: 1rem;
  background-color: $white;
}

.logo-spinner {
  @include flexCenter();
  .spinner {
    position: relative;
    &:not(.bg-none):before {
      position: absolute;
      content: "";
      width: 25%;
      left: 50%;
      margin-left: -12.5%;
      height: 50%;
      top: 50%;
      margin-top: -25%;
      background: url('#{$iconsSvgUrl}/logo/keyhole.svg') 0 0 no-repeat;
      background-size: cover;
    }
    &.loading {
      img {
        animation: spin 3s linear infinite;
      }
    }
  }
}

@keyframes spin { 100% { transform:rotate(360deg); } }

.text-success {
  color: $green;
}

.text-error {
  color: $error;
}

.table-col-header-top {
  th {
    vertical-align: top;
  }
}