@import '../variables';
@import '../mixins';

.income-list {
  h4 {
    text-align: left !important;
  }
}

.income-form {
  margin-bottom: 1rem;
}

.addIncomeButton {
  margin-left: 1rem;
}
