@import '../variables';
@import '../mixins';

.reports {
  &-agent {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    .button {
      margin: 0 1rem;
    }
  }
}