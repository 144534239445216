@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button {
  @include flexCenter();
  flex: 1;
  height: 44px;
  border-radius: 4px;
  cursor: pointer;
}