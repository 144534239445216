@import '../variables';
@import '../mixins';

.auth {
  @include flexCenter();
  flex-direction: column;
  margin: 100px;

  &-form {
    width: 300px;

    .button-block {
      text-align: center;

      button {
        width: 150px;
      }
    }
  }

  h2 {
    margin-bottom: 2rem;
  }

  &-line {
    @include flexCenter();
    margin-top: 2rem;

    > button {
      @include button();
      min-width: 150px;
      padding: 0 16px;
      background-position: right center;
      border-color: transparent;
      background-repeat: no-repeat;
      background-origin: content-box;
      justify-content: flex-start;
      background-color: $white;
      border: 1px solid $black10 !important;
      color: $black;
      font-weight: 600;
      line-height: 24px;

      &.yandex {
        background-image: url('#{$iconsSvgUrl}/social/yandex.svg');
        margin-right: 1rem;
      }

      &.google {
        background-image: url('#{$iconsSvgUrl}/social/google.svg');
      }
    }
  }
}
