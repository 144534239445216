@import '../variables';
@import '../mixins';

.users {
  padding: 0.5rem;

  h3 {
    text-align: left !important;
  }

  .header-filters {
    display: flex;
  }
}

.user {
  padding: 0.5rem;

  h3 {
    text-align: left !important;
  }

  &-card {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;

    b {
      margin-right: 1rem;
    }

    &-name {
      h3 {
        margin-bottom: 0;
      }

      text-align: left;
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      align-self: center;
    }

    &-avatar {
      align-self: center;
    }

    &-agent {
      margin-left: 2rem;
      width: 600px;


      .ant-form-item {
        margin-bottom: 0;
      }

      &-row {
        margin-bottom: 0.5rem;
      }

      .cancel {
        margin-left: 1rem;
      }

      .buttons {
        margin-top: 0.5rem;
      }
    }
  }

  &-card.col {
    flex-direction: column;
  }

  &-info {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;

    > div {
      display: flex;
      width: 100%;
      height: 30px;

      .ant-typography {
        margin-bottom: 0;
        flex: 1;
      }
    }

    &.card {
      padding-left: 0;
    }

    p {
      text-align: left;

      b {
        margin-right: 1rem;
      }
    }

    span {
      text-align: left;
    }
  }

  &-banned {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    button {
      margin-left: 1rem;
    }
    .colorBanned {
      color: $error;
    }
    .colorActive {
      color: $green;
    }
  }

  &-auth-data {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      > b {
        width: 130px;
      }
    }
  }
}

.influencer-status {
  height: 100%;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    margin-bottom: 0.5rem;
  }
}

.paragraphTitle {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}

.userSocial {
  width: 350px;
  margin-right: 16px;
  .title {
    font-size: 10px;
    font-weight: bold;
  }
}