@import '../variables';
@import '../mixins';

.promo {
  padding: 0.5rem;
  .addLikes {
    .ant-input-affix-wrapper {
      width: 300px;
    }
    .ant-input-affix-wrapper, .ant-input-number {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    button {
      margin-left: 1rem;
    }
  }
}