.paymentData {
    padding: 0.5rem 0.5rem 3rem 0.5rem;
    .ant-input-affix-wrapper {
        width: 300px;
    }
    .item {
        margin-top: 2rem;

        .ant-typography code {
            display: inline-block;
            white-space: pre-line;
        }

        .ant-divider-horizontal.ant-divider-with-text-left::before {
            width: 0;
        }
        .ant-divider-inner-text {
            margin-left: 0;
            padding-left: 0;
        }
    }
}
