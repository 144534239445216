.main {
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main .main-item {
    margin-bottom: 20px;
}

.main .main-item button {
    width: 200px;
}